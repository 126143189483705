import { Alert } from "@mui/material";
import styled from "styled-components";

const browserAlert = styled(Alert)`
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 50px;
  text-align: center;
  font-weight: bold;
`;

export default { browserAlert };
