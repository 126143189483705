import { useState } from "react";
import { Box, Button } from "@mui/material";

import { CorpSizeType } from "@sellernote/_shared/src/types/common/team";
import { JoinedAllTeamItem } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { TeamMember } from "@sellernote/_shared/src/types/forwarding/adminTeam";

import IndividualOrTeamSelectModal from "./IndividualOrTeamSelectModal";
import TeamMemberSelectModal from "./TeamMemberSelectModal";

function BidCreate({
  joinedAllTeam,
  userId,
  userName,
  userCompany,
  teamId,
  corpSizeType,
  isUserCreate,
  teamName,
  teamMemberList,
}: {
  joinedAllTeam?: JoinedAllTeamItem[];
  userId?: number;
  userName?: string;
  userCompany?: string;
  teamId?: number;
  corpSizeType: CorpSizeType;
  isUserCreate: boolean;
  teamName?: string;
  teamMemberList?: TeamMember[];
}) {
  const [
    opensIndividualOrTeamSelectModal,
    setOpensIndividualOrTeamSelectModal,
  ] = useState(false);
  const [opensTeamMemberSelectModal, setOpensTeamMemberSelectModal] =
    useState(false);

  const [isPOCrate, setIsPOCrate] = useState(false);

  const handleBidCreateModalOpen = () => {
    setIsPOCrate(false);
    setOpensIndividualOrTeamSelectModal(true);
  };

  const handlePOCreateModalOpen = () => {
    setIsPOCrate(true);
    setOpensIndividualOrTeamSelectModal(true);
  };

  const handleTeamShipmentCreateModalOpen = () => {
    setOpensTeamMemberSelectModal(true);
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {isUserCreate && (
        <Button variant="contained" onClick={handlePOCreateModalOpen}>
          발주 생성
        </Button>
      )}

      {isUserCreate ? (
        <Button variant="contained" onClick={handleBidCreateModalOpen}>
          의뢰 생성
        </Button>
      ) : (
        <Button variant="contained" onClick={handleTeamShipmentCreateModalOpen}>
          의뢰 생성
        </Button>
      )}

      {opensIndividualOrTeamSelectModal &&
        userId &&
        userName &&
        teamId &&
        corpSizeType && (
          <IndividualOrTeamSelectModal
            joinedAllTeam={joinedAllTeam}
            userId={userId}
            teamId={teamId}
            userName={userName}
            userCompany={userCompany || ""}
            opensIndividualOrTeamSelectModal={opensIndividualOrTeamSelectModal}
            setOpensIndividualOrTeamSelectModal={
              setOpensIndividualOrTeamSelectModal
            }
            isPOCreate={isPOCrate}
            corpSizeType={corpSizeType}
          />
        )}

      {opensTeamMemberSelectModal && teamName && teamId && teamMemberList && (
        <TeamMemberSelectModal
          teamName={teamName}
          teamMemberList={teamMemberList}
          teamId={teamId}
          opensTeamMemberSelectModal={opensTeamMemberSelectModal}
          setOpensTeamMemberSelectModal={setOpensTeamMemberSelectModal}
          userCompany={userCompany || ""}
          corpSizeType={corpSizeType}
        />
      )}
    </Box>
  );
}

export default BidCreate;
