import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import CelebrationIcon from "@mui/icons-material/Celebration";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExploreIcon from "@mui/icons-material/Explore";
import GroupsIcon from "@mui/icons-material/Groups";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonIcon from "@mui/icons-material/Person";
import PriceChangeRoundedIcon from "@mui/icons-material/PriceChangeRounded";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ScheduleSharpIcon from "@mui/icons-material/ScheduleSharp";
import ScienceIcon from "@mui/icons-material/Science";

import { AdminMenuTree } from "../../../types/forwarding/adminMenu";

//TODO: url 명 kebab-case로 변경
const MUI_ICON_STYLE = {
  color: "#eeeeee",
  fontSize: "17px",
  cursor: "pointer",
};

const SHIPDA_ADMIN_MENU_TREE: AdminMenuTree = [
  {
    label: "기본",
    mainMenuList: [
      {
        type: "link",
        icon: <DashboardIcon sx={MUI_ICON_STYLE} />,
        label: "대시보드",
        link: "/dashboard",
      },
      {
        type: "parent",
        icon: <CelebrationIcon sx={MUI_ICON_STYLE} />,
        label: "프로모션 관리",
        subMenuList: [
          {
            type: "link",
            label: "프로모션 관리",
            link: "/promotion",
          },
        ],
      },
      {
        type: "parent",
        icon: <BusinessCenterRoundedIcon sx={MUI_ICON_STYLE} />,
        label: "영업 관리",
        subMenuList: [
          {
            type: "link",
            label: "영업 현황",
            link: "/business-management/business-overview",
          },
          {
            type: "link",
            label: "운영/정산 현황",
            link: "/business-management/operation",
          },
          {
            type: "link",
            label: "손익 현황",
            link: "/business-management/profit",
          },
          {
            type: "link",
            label: "가이드 신청 리스트",
            link: "/business-management/guide",
          },
        ],
      },
      {
        type: "link",
        icon: <AssignmentIcon sx={MUI_ICON_STYLE} />,
        label: "발주 관리",
        link: "/order",
      },
      {
        type: "parent",
        icon: <JoinLeftIcon sx={MUI_ICON_STYLE} />,
        label: "의뢰 관리",
        subMenuList: [
          {
            type: "link",
            label: "선적계획 의뢰",
            link: "/bid",
          },
          {
            type: "link",
            label: "선적미계획 의뢰",
            link: "/order/sub",
          },
        ],
      },
      {
        type: "parent",
        icon: <JoinRightIcon sx={MUI_ICON_STYLE} />,
        label: "운영 관리",
        subMenuList: [
          {
            type: "link",
            label: "수입(일반)",
            link: "/trello/general",
          },
          {
            type: "link",
            label: "수입(특송)",
            link: "/trello/consolidation",
          },
          {
            type: "link",
            label: "수출(일반)",
            link: "/trello/export",
          },
        ],
      },
      {
        type: "parent",
        icon: <MonetizationOnIcon sx={MUI_ICON_STYLE} />,
        label: "정산",
        subMenuList: [
          {
            type: "link",
            label: "정산",
            link: "/settlement",
          },
          {
            type: "link",
            label: "매출 관리",
            link: "/settlement/salesManagement",
          },
          {
            type: "link",
            label: "매입 관리",
            link: "/settlement/purchaseManagement",
          },
          {
            type: "link",
            label: "입금 관리",
            link: "/settlement/deposit-management",
          },
          {
            type: "link",
            label: "출금 관리",
            link: "/settlement/withdrawal-management",
          },
        ],
      },
      {
        type: "link",
        icon: <LocalActivityIcon sx={MUI_ICON_STYLE} />,
        label: "쿠폰",
        link: "/coupon",
      },
      {
        type: "parent",
        icon: <ScheduleSharpIcon sx={MUI_ICON_STYLE} />,
        label: "운항 스케줄",
        subMenuList: [
          {
            type: "link",
            label: "FCL",
            link: "/vessel-schedule/fcl",
          },
          {
            type: "link",
            label: "LCL(항구)",
            link: "/vessel-schedule/lcl/port",
          },
          {
            type: "link",
            label: "LCL(창고)",
            link: "/vessel-schedule/lcl/warehouse",
          },
          {
            type: "link",
            label: "AIR",
            link: "/vessel-schedule/air",
          },
        ],
      },
      {
        type: "parent",
        icon: <ScienceIcon sx={MUI_ICON_STYLE} />,
        label: "실험실",
        subMenuList: [
          {
            type: "link",
            label: "입금관리 실험실",
            link: "/lab/deposit-management",
          },
        ],
      },
    ],
  },
  {
    label: "계정",
    mainMenuList: [
      {
        type: "link",
        icon: <PersonIcon sx={MUI_ICON_STYLE} />,
        label: "사용자 관리",
        link: "/users/list",
      },

      {
        type: "link",
        icon: <GroupsIcon sx={MUI_ICON_STYLE} />,
        label: "팀 관리",
        link: "/team/list",
      },

      {
        type: "link",
        icon: <RecentActorsIcon sx={MUI_ICON_STYLE} />,
        label: "파트너 관리",
        link: "/partner-management",
      },

      {
        type: "link",
        icon: <RecentActorsIcon sx={MUI_ICON_STYLE} />,
        label: "거래처 관리",
        link: "/account-management",
      },

      {
        type: "link",
        icon: <AdminPanelSettingsIcon sx={MUI_ICON_STYLE} />,
        label: "관리자 설정",
        link: "/admin-user",
      },

      /**
       * 신규 파트너 관리 배포로, Legacy 파트너 관리 메뉴는 잠깐 숨김 (긴급상황 대비 삭제는 X)
       * 메뉴에서 접근은 숨기고, URL을 통한 라우팅은 가능하도록 함
       */
      // {
      //   type: "parent",
      //   icon: <SolutionOutlined key="icon" />,
      //   label: "Legacy 파트너 관리",
      //   subMenuList: [
      //     {
      //       type: "link",
      //       label: "해외",
      //       link: "/partner/foreign",
      //     },
      //     {
      //       type: "link",
      //       label: "국내",
      //       link: "/partner/domestic",
      //     },
      //     {
      //       type: "link",
      //       label: "운영파트너",
      //       link: "/partner/operator",
      //     },
      //     {
      //       type: "link",
      //       label: "관세사어드민",
      //       link: "/partner/customs",
      //     },
      //   ],
      // },
    ],
  },
  {
    label: "운임/요금",
    mainMenuList: [
      {
        type: "parent",
        icon: <PriceChangeRoundedIcon sx={MUI_ICON_STYLE} />,
        label: "운임 관리",
        subMenuList: [
          {
            type: "link",
            label: "FCL 운임",
            link: "/fare-management/fcl/list",
          },
          {
            type: "link",
            label: "LCL 운임",
            link: "/fare-management/lcl/list",
          },
          {
            type: "link",
            label: "AIR 운임",
            link: "/fare-management/air/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <LocalShippingIcon sx={MUI_ICON_STYLE} />,
        label: "내륙요금 관리",
        subMenuList: [
          {
            type: "link",
            label: "FCL",
            link: "/inland-fare/fcl/list",
          },
          {
            type: "link",
            label: "LCL",
            link: "/inland-fare/lcl/list",
          },
          {
            type: "link",
            label: "AIR",
            link: "/inland-fare/air/list",
          },
        ],
      },
      {
        type: "parent",
        icon: <ExploreIcon sx={MUI_ICON_STYLE} />,
        label: "Place",
        subMenuList: [
          {
            type: "link",
            label: "장소 관리",
            link: "/place/zones",
          },
          {
            type: "link",
            label: "향구/공항 관리",
            link: "/place/ports",
          },
          {
            type: "link",
            label: "국가 관리",
            link: "/place/countryList",
          },
        ],
      },
    ],
  },
];

const PARTNER_ADMIN_MENU_TREE: AdminMenuTree = [
  {
    label: "기본",
    mainMenuList: [
      {
        type: "parent",
        icon: <JoinLeftIcon sx={MUI_ICON_STYLE} />,
        label: "의뢰 관리",
        subMenuList: [
          {
            type: "link",
            label: "쉽다 의뢰",
            link: "/bid",
          },
        ],
      },
      {
        type: "parent",
        icon: <JoinRightIcon sx={MUI_ICON_STYLE} />,
        label: "운영 관리",
        subMenuList: [
          {
            type: "link",
            label: "수입(일반)",
            link: "/trello/general",
          },
          {
            type: "link",
            label: "수입(특송)",
            link: "/trello/consolidation",
          },
        ],
      },
    ],
  },
];

export { SHIPDA_ADMIN_MENU_TREE, PARTNER_ADMIN_MENU_TREE };
